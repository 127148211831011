import React from 'react';
import { shape } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Loader from '@components/Loader';
import Loadable from 'react-loadable';
import { round } from 'lodash';

const errorLoading = (err) =>
  console.log('Dynamic home page loading failed', err);

const HomePage = Loadable({
  loader: () => import('@routes/Home').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const routeBooksDetail = Loadable({
  loader: () => import('@routes/BookDetails').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const CatholiceBookRoute = Loadable({
  loader: () => import('@routes/CatholiceBooks').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const LogInRoute = Loadable({
  loader: () => import('@routes/Login').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const CbrainLogInRoute = Loadable({
  loader: () => import('@routes/CbrainLogin').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const RegisterRoute = Loadable({
  loader: () => import('@routes/Register').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const ForgotPasswordRoute = Loadable({
  loader: () => import('@routes/ForgotPassword').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const ResetPasswordRoute = Loadable({
  loader: () => import('@routes/PasswordReset').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const ForgotUsernameRoute = Loadable({
  loader: () => import('@routes/ForgotUsername').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const ReaderRoute = Loadable({
  loader: () => import('@routes/ReactEpub').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const ReaderRouteNew = Loadable({
  loader: () => import('@routes/BookViewer').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const PreviewRoute = Loadable({
  loader: () => import('@routes/Preview').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});
const FreeTrailRoute = Loadable({
  loader: () => import('@routes/FreeTrial').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const BooksInCart = Loadable({
  loader: () => import('@routes/Cart').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const PurchasedBooksInCart = Loadable({
  loader: () => import('@routes/PurchasedBooksList').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

const AudioBooksRoute = Loadable({
  loader: () => import('@routes/AudioBooks').catch(errorLoading),
  loading: () => <Loader />, // full page loader here
  delay: 200, // delay in milliseconds, useful for suspense
});

// const TestEpubRoute = Loadable({
//   loader: () => import('@routes/TestEpub').catch(errorLoading),
//   loading: () => <Loader />, // full page loader here
//   delay: 200, // delay in milliseconds, useful for suspense
// });

// if (process.browser) {
//   localStorage.setItem("count", 0);
// }

const Routes = (props) => {
  const { history, location, match } = props;

  return (
    <Switch>
      <Route key="home" exact path="/" component={HomePage} />
      {/* <Route key="test" exact path="/testepub" component={TestEpubRoute} /> */}
      {/* <Route key="Side" exact path="/side" component={SideMenu} /> */}

      <Route
        key="details"
        exact
        path="/catholicebooks-detail/:bookId"
        component={routeBooksDetail}
      />

      <Route
        key="catholicebook"
        exact
        path="/catholicebook"
        component={CatholiceBookRoute}
      />
      <Route key="login" exact path="/login" component={LogInRoute} />
      <Route
        key="freetrial"
        exact
        path="/free-trial"
        component={FreeTrailRoute}
      />
      <Route key="register" exact path="/register" component={RegisterRoute} />
      <Route
        key="forgot-password"
        exact
        path="/forgot-password"
        component={ForgotPasswordRoute}
      />
      <Route
        key="reset-password"
        exact
        path="/password/reset-password"
        component={ResetPasswordRoute}
      />
      <Route
        key="forgot-username"
        exact
        path="/forgot-username"
        component={ForgotUsernameRoute}
      />
      <Route key="reader" exact path="/epub" component={ReaderRoute} />
       <Route key="reader" exact path="/bookviewer" component={ReaderRouteNew} />
      <Route key="preview" exact path="/preview" component={PreviewRoute} />
      <Route
        key="audiobook"
        exact
        path="/audiobooks"
        component={AudioBooksRoute}
      />
      <Route key="cart" exact path="/ebookCart" component={BooksInCart} />
      <Route
        key="purchased"
        exact
        path="/myBooks"
        component={PurchasedBooksInCart}
      />
      <Route
        key="catholicbrain/login"
        exact
        path="/catholicbrain/login"
        component={CbrainLogInRoute}
      />
    </Switch>
  );
};

Routes.propTypes = {
  history: shape({}).isRequired,
  location: shape({}).isRequired,
  match: shape({}).isRequired,
};

const RootRoutes = () => <Route component={Routes} />;

export default RootRoutes;
